import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import classes from './RoutesMenu.module.scss';
import { formatTime } from '../../utils';
import { ReactComponent as RouteIcon } from '../../assets/icons/route.svg';
import { ReactComponent as DistanceIcon } from '../../assets/icons/distance.svg';
import { ReactComponent as TimeIcon } from '../../assets/icons/time.svg';
import { ReactComponent as SlopeIcon } from '../../assets/icons/slope.svg';

const RoutesMenu = (props) => {

	const {
		routes,
		routesFilters,
		selectRoute,
		t
	} = props;

	const [ sorting, setSorting ] = useState({ type: 'name', asc: true });

	const changeSorting = type => {
		setSorting(prevSorting => ({
			type,
			asc: (prevSorting.type === type) ? !prevSorting.asc : true
		}));
	}

	const filterFunction = route => (
		route.distance >= routesFilters.distance.min
		&& route.distance <= routesFilters.distance.max
		&& route.time >= routesFilters.time.min * 60
		&& route.time <= routesFilters.time.max * 60
	);

	const compareFunction = (a, b) => sorting.asc
		? (a[sorting.type] < b[sorting.type]) ? -1 : (a[sorting.type] > b[sorting.type]) ? 1 : 0
		: (a[sorting.type] < b[sorting.type]) ? 1 : (a[sorting.type] > b[sorting.type]) ? -1 : 0;

	let routesList = null;
	if(routes) {
		const filteredRoutes = Object.values(routes).filter(filterFunction);
		const sortedRoutes = filteredRoutes.sort(compareFunction);
		routesList = sortedRoutes.map(route => (
			<li
				key={route.id}
				className={classes.route}
				onClick={() => selectRoute(route.id)}
			>
				<span className={classes.routeName}>
					{route.name}
				</span>
				<div className={classes.routeDetails}>
					<span>{`${route.distance} ${t('common.km')}`}</span>
					<span>{`${formatTime(route.time)} ${t('common.hours')}`}</span>
					<span>{`${route.slope} ${t('common.slope')}`}</span>
				</div>
			</li>
		))
	}

	return (
		<React.Fragment>
			<div className={classes.headerContainer}>
				<div className={classes.headerIconContainer}>
					<RouteIcon className={classes.routeIcon} />
				</div>
				<h1 className={classes.header}>
					{t('routesMenu.header')}
				</h1>
			</div>
			<div className={classes.sortingContainer}>
				<span className={classes.sortingText}>
					{t('routesMenu.sortingText')}
				</span>
				<div className={classes.sortingButtonsContainer}>
					<button
						className={`button ${(sorting.type === 'name') ? classes.sortingButtonSelected : classes.sortingButton}`}
						onClick={() => changeSorting('name')}
					>
						{t('common.az')}
					</button>
					<button
						className={`button ${(sorting.type === 'distance') ? classes.sortingButtonSelected : classes.sortingButton}`}
						onClick={() => changeSorting('distance')}
					>
						<DistanceIcon className={classes.distanceIcon}/>
						<span>{t('common.km')}</span>
					</button>
					<button
						className={`button ${(sorting.type === 'time') ? classes.sortingButtonSelected : classes.sortingButton}`}
						onClick={() => changeSorting('time')}
					>
						<TimeIcon className={classes.timeIcon}/>
						<span>{t('common.hours')}</span>
					</button>
					<button
						className={`button ${(sorting.type === 'slope') ? classes.sortingButtonSelected : classes.sortingButton}`}
						onClick={() => changeSorting('slope')}
					>
						<SlopeIcon className={classes.slopeIcon}/>
						<span>%</span>
					</button>
				</div>
			</div>
			<div className={classes.routesContainer}>
				<ul>
					{routesList}
				</ul>
			</div>
		</React.Fragment>
	);
};

export default withTranslation()(RoutesMenu);