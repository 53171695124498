import React from 'react';
import { withTranslation } from 'react-i18next';
import classes from './Legal.module.scss';
import logo from '../../assets/logos/greenpin.svg';

const Cookies = ({ t }) => (
	<div className={classes.pageContainer}>
		<div className={classes.logoContainer}>
    	<span>Greenpin</span>
      <img src={logo} alt='logo' />
    </div>
    <h1 className={classes.header}>
    	{t('cookiesPolicy.header')}
    </h1>
    <div className={classes.block}>
	    <h2 className={classes.subheader}>{t('cookiesPolicy.what.subheader')}</h2>
	    <p>
	    	{t('cookiesPolicy.what.par1a')}
	    	<span className={classes.highlight}>https://greenpin.uma.es</span>
	    	{t('cookiesPolicy.what.par1b')}
	    </p>
	    <p>
	    	{t('cookiesPolicy.what.par2')}
	    	<span className={classes.highlight}>https://greenpin.uma.es</span>.
	    </p>
    </div>
    <div className={classes.block}>
	    <h2 className={classes.subheader}>{t('cookiesPolicy.types.subheader')}</h2>
	    <p>{t('cookiesPolicy.types.par1')}</p>
	    <ul>
		    <li><p>
		    	<span className={classes.bold}>{t('cookiesPolicy.types.par2bold')}</span>
		    	{t('cookiesPolicy.types.par2')}
		    </p></li>
		    <li><p>
		    	<span className={classes.bold}>{t('cookiesPolicy.types.par3bold')}</span>
		    	{t('cookiesPolicy.types.par3a')}
		    	<span className={classes.highlight}>https://greenpin.uma.es</span>
		    	{t('cookiesPolicy.types.par3b')}
		    </p></li>
		    <li><p>
		    	<span className={classes.bold}>{t('cookiesPolicy.types.par4bold')}</span>
		    	{t('cookiesPolicy.types.par4a')}
		    	<span className={classes.highlight}>https://greenpin.uma.es</span>
		    	{t('cookiesPolicy.types.par4b')}
		    </p></li>
		    <li><p>
		    	<span className={classes.bold}>{t('cookiesPolicy.types.par5bold')}</span>
		    	{t('cookiesPolicy.types.par5')}
		    </p></li>
		    <li><p>
		    	<span className={classes.bold}>{t('cookiesPolicy.types.par6bold')}</span>
		    	{t('cookiesPolicy.types.par6')}
		    </p></li>
	    </ul>
	  </div>
	  <div className={classes.block}>
	    <h2 className={classes.subheader}>{t('cookiesPolicy.use.subheader')}</h2>
	    <p>
	    	{t('cookiesPolicy.use.par1a')}
	    	<span className={classes.highlight}>https://greenpin.uma.es</span>
	    	{t('cookiesPolicy.use.par1b')}
	    </p>
	    <ul>
		    <p className={classes.bold}>{t('cookiesPolicy.use.subheaderA')}</p>
		    <p>{t('cookiesPolicy.use.par2')}</p>
		    <p>{t('cookiesPolicy.use.par3')}</p>
		    <table className={classes.table}>
					<thead>
						<tr>
							<th className={classes.tableHeader} colSpan="2">
								{t('cookiesPolicy.use.tableA.title')}
							</th>
						</tr>
						<tr>
							<th className={classes.colName}>{t('cookiesPolicy.use.tableA.name')}</th>
							<th className={classes.colPurpose}>{t('cookiesPolicy.use.tableA.purpose')}</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>{t('cookiesPolicy.use.tableA.name1')}</td>
							<td>{t('cookiesPolicy.use.tableA.purpose1')}</td>
						</tr>
						<tr>
							<td>{t('cookiesPolicy.use.tableA.name2')}</td>
							<td>{t('cookiesPolicy.use.tableA.purpose2')}</td>
						</tr>
						<tr>
							<td>{t('cookiesPolicy.use.tableA.name3')}</td>
							<td>{t('cookiesPolicy.use.tableA.purpose3')}</td>
						</tr>
						<tr>
							<td>{t('cookiesPolicy.use.tableA.name4')}</td>
							<td>{t('cookiesPolicy.use.tableA.purpose4')}</td>
						</tr>
					</tbody>
				</table>
				<p className={classes.bold}>{t('cookiesPolicy.use.subheaderB')}</p>
		    <p>{t('cookiesPolicy.use.par4')}</p>
		    <table className={classes.table}>
					<thead>
						<tr>
							<th className={classes.tableHeader} colSpan="2">
								{t('cookiesPolicy.use.tableB.title')}
							</th>
						</tr>
						<tr>
							<th className={classes.colName}>{t('cookiesPolicy.use.tableB.name')}</th>
							<th className={classes.colPurpose}>{t('cookiesPolicy.use.tableB.purpose')}</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>{t('cookiesPolicy.use.tableB.name1')}</td>
							<td>{t('cookiesPolicy.use.tableB.purpose1')}</td>
						</tr>
					</tbody>
				</table>
			</ul>
	  </div>
	  <div className={classes.block}>
	    <h2 className={classes.subheader}>{t('cookiesPolicy.settings.subheader')}</h2>
	    <p>{t('cookiesPolicy.settings.par1')}</p>
	    <p>{t('cookiesPolicy.settings.par2')}</p>
	    <ul>
	    	<p>
	    		<span className={classes.bold}>{t('cookiesPolicy.settings.par3bold')}</span>
	    		{t('cookiesPolicy.settings.par3')}
	    		<br />
	    		<a className={classes.highlight} href='https://support.google.com/chrome/answer/95647?hl=es'>
	    			https://support.google.com/chrome/answer/95647?hl=es
	    		</a>
	    	</p>
	    	<p>
	    		<span className={classes.bold}>{t('cookiesPolicy.settings.par4bold')}</span>
	    		{t('cookiesPolicy.settings.par4')}
	    		<br />
	    		<a className={classes.highlight} href='https://support.mozilla.org/es/products/firefox/privacy-and-security'>
	    			https://support.mozilla.org/es/products/firefox/privacy-and-security
	    		</a>
	    	</p>
	    	<p>
	    		<span className={classes.bold}>{t('cookiesPolicy.settings.par5bold')}</span>
	    		{t('cookiesPolicy.settings.par5')}
	    		<a className={classes.highlight} href='https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies'>
	    			https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies
	    		</a>
	    	</p>
	    	<p>
	    		<span className={classes.bold}>{t('cookiesPolicy.settings.par6bold')}</span>
	    		{t('cookiesPolicy.settings.par6')}
	    		<a className={classes.highlight} href='https://help.opera.com/en/how-to-clear-browser-cache-history-cookies/'>
	    			https://help.opera.com/en/how-to-clear-browser-cache-history-cookies/
	    		</a>
	    	</p>
	    </ul>
	  </div>
	</div>
);

export default withTranslation()(Cookies);