import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import classes from './Header.module.scss';
import { MODAL_PAGES } from '../../config';
import headerBackground from '../../assets/img/header-background.svg';
import headerBackgroundMobile from '../../assets/img/header-background-mobile.svg';
import logo from '../../assets/logos/greenpin.svg';
import bronzeIcon from '../../assets/icons/bronze.svg';
import silverIcon from '../../assets/icons/silver.svg';
import goldIcon from '../../assets/icons/gold.svg';
import { ReactComponent as HelpIcon } from '../../assets/icons/help.svg';

const ranksIcons = { bronze: bronzeIcon, silver: silverIcon, gold: goldIcon };

const Header = (props) => {

	const [ menuOpen, setMenuOpen ] = useState(false);

	const {
		isAuthenticated,
		currentUser,
		setAuthPanel,
		setModalPage,
		showIntro,
		logout,
		t
	} = props;

	const toggleMenu = () => {
		setMenuOpen(prevMenuOpen => !prevMenuOpen);
	}

	const handleAuthClick = () => {
		setMenuOpen(false);
		if(isAuthenticated) {
			setModalPage(MODAL_PAGES.USER_PANEL, null);
		} else {
			setAuthPanel(true);
		}
	}

	const handleRulesClick = () => {
		setMenuOpen(false);
		setModalPage(MODAL_PAGES.RULES, null);
	}

	const handleIntroClick = () => {
		setMenuOpen(false);
		showIntro();
	}

	const handleLogout = () => {
		setMenuOpen(false);
		logout();
	}

	return (
		<header 
			className={classes.header}
		>
			<nav 
				className={`intro-steps-zero ${classes.navbar}`}
				style={(window.innerWidth > 768) ? { backgroundImage: `url(${headerBackground})` } : { backgroundImage: `url(${headerBackgroundMobile})` }}
			>
			  <div className={classes.navbarStart}>
			    <a href='/' className={classes.logoContainer}>
			    	<span>Greenpin</span>
			      <img src={logo} alt='logo' />
			    </a>
			    <div className={classes.secondaryText}>
			    	Montes de Málaga
			    </div>
			  </div>
			  <div className={classes.navbarEnd}>
			    	{isAuthenticated ? (
			    		<button 
					    	className={`navbar-item button intro-steps-one ${classes.navbarAvatar}`}
					    	onClick={toggleMenu}
					    >
			    			<img className={classes.navbarAvatarIcon} src={ranksIcons[currentUser.rank]} alt='rank' />
				    	</button>
			    	) : (
					    <button 
					    	className={`navbar-item button intro-steps-one ${menuOpen ? classes.navbarBurgerOpen : classes.navbarBurger}`}
					    	onClick={toggleMenu}
					    >
					      <span></span>
					      <span></span>
					      <span></span>
					    </button>
				    )}
			  </div>
			  <div className={menuOpen ? classes.navbarMenuOpen : classes.navbarMenu}>
			  	<ul className={classes.navbarMenuList}>
			  		{isAuthenticated && (
			  			<li className={classes.navbarMenuUsername}>
			  				<span>
			  					{currentUser.username}
			  				</span>
			  				<span>
			  					{`(${currentUser.pins.length} ${t('common.pins')})`}
			  				</span>
			  			</li>
			  		)}
			  		<li onClick={handleAuthClick}>
			  			{isAuthenticated ? t('header.userPanel') : t('header.auth')}
			  		</li>
			  		<li onClick={handleRulesClick}>
			  			{t('header.rules')}
			  		</li>
			  		<li>
			  			<a href='mailto:contact@cartometrics.com'>{t('header.contact')}</a>
			  		</li>
			  		<li className={classes.navbarMenuHelp} onClick={handleIntroClick}>
			  			<HelpIcon className={classes.navbarMenuHelpIcon} />
			  		</li>
			  		{isAuthenticated && (
			  			<li onClick={handleLogout}>
			  				{t('header.logout')}
			  			</li>
			  		)}
			  	</ul>
			  </div>
			</nav>			
		</header>
	);
};

export default withTranslation()(Header);