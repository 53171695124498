import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import classes from './PinPopup.module.scss';
import { SITE_URL, MODAL_PAGES, API_URL } from '../../config';
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import { ReactComponent as TwitterIcon } from '../../assets/icons/twitter.svg';
import { ReactComponent as FacebookIcon } from '../../assets/icons/facebook.svg';
import { ReactComponent as LinkIcon } from '../../assets/icons/link.svg';

const PinPopup = (props) => {

	const [ linkCopied, setLinkCopied ] = useState(false);

	const {
		show,
		pin,
		currentUserId,
		setModalPage,
		selectPin,
		t
	} = props;

	const openPinEditor = pinId => {
		selectPin(null);
		setModalPage(MODAL_PAGES.EDIT_PIN, { pinId });
	}

	let popupContent = null;
	if(show) {
		const pinUrl = `${SITE_URL}?pin=${pin.id}`;
		
		const twitterText = `${t('socialMedia.pin')} ${pin.name}`;
		const twitterIntentHref = `https://twitter.com/intent/tweet?url=${pinUrl}&text=${encodeURIComponent(twitterText)}&hashtags=greenpin`;
		
		const shareOnFacebook = () => {
	    window.FB.ui({
	      method: 'share',
	      href: pinUrl,
	      hashtag: '#greenpin'
	    }, function(response){});
	  }

	  const copyRouteUrl = () => {
			setLinkCopied(true);
			window.setTimeout(() => setLinkCopied(false), 450);
			const el = document.createElement('input');
	    el.value = pinUrl;
	    el.className = classes.hiddenUrlInput;
	    document.body.appendChild(el);
	    el.select();
	    document.execCommand("copy");
	    el.remove();
		}

		popupContent = (
			<React.Fragment>
				{(currentUserId === pin.user) && (
					<button
						className={`button ${classes.editButton}`}
						onClick={() => openPinEditor(pin.id)}
					>
						<EditIcon className={classes.editIcon}/>
					</button>
				)}
				<button 
					className={`delete is-medium ${classes.closeButton}`}
					onClick={() => selectPin(null)}>
				</button>
				<img
					className={classes.image}
					src={API_URL + pin.image.url}
					alt={pin.name}
				/>
				<h1 className={classes.header}>
					{pin.name}
				</h1>
				<div className={classes.description}>
					{pin.description.split('\n').map((par, idx) => (
						<p key={idx}>
							{par}
						</p>
					))}
				</div>
				<div className={classes.socialMedia}>
					<a
						href={twitterIntentHref}
						className={classes.twitterContainer}
						target='_blank'
						rel='noopener noreferrer'
					>
						<TwitterIcon className={classes.socialMediaIcon}/>
					</a>
					<div
						className={classes.facebookContainer}
						onClick={shareOnFacebook}
					>
						<FacebookIcon className={classes.socialMediaIcon} />
					</div>
					<div className={classes.linkContainer}>
						<LinkIcon
							className={linkCopied ? classes.copiedLinkIcon : classes.linkIcon}
							onClick={copyRouteUrl}
						/>
					</div>
				</div>
			</React.Fragment>
		);
	}

	return (
		<div className={show ? classes.popupShown : classes.popup}>
			{popupContent}
		</div>
	);
};

export default withTranslation()(PinPopup);