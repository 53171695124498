import React from 'react';
import { Router, Link } from "@reach/router";
import CookieConsent from "react-cookie-consent";
import { withTranslation } from 'react-i18next';
import Main from './components/Main/Main';
import PasswordReset from './components/PasswordReset/PasswordReset';
import PrivacyPolicy from './components/Legal/PrivacyPolicy';
import Cookies from './components/Legal/Cookies';

const App = ({ t }) => (
	<React.Fragment>
	  <Router>
	    <Main default />
	    <PasswordReset path='/reset-password' />
	    <PrivacyPolicy path='/politica-privacidad' />
	    <Cookies path='/cookies' />
	  </Router>
	  <CookieConsent
	    location="bottom"
	    buttonText={t('cookies.button')}
	  >
	    {t('cookies.text')}
	    <Link to="/cookies" className="cookies-link">{t('cookies.link')}</Link>
	  </CookieConsent>
  </React.Fragment>
);

export default withTranslation()(App);