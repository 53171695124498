import React from 'react';
import classes from './ConfirmationMessage.module.scss';

const ConfirmationMessage = (props) => {

	const {
		show,
		header,
		text,
		cancel,
		confirm,
		sending,
		handleCancel,
		handleConfirm
	} = props;

	return (
		<div className={`modal ${show ? 'is-active' : ''}`}>
		  <div className='modal-background' onClick={handleCancel}></div>
		  <div className={`modal-content ${classes.messageContainer}`}>
		  	<h1 className={classes.header}>
		  		{header}
		  	</h1>
		  	<p className={classes.text}>
		  		{text}
		  	</p>
		  	<div className={classes.buttonsContainer}>
		  		<button
		  			className={`button is-rounded`}
		  			onClick={handleCancel}
		  		>
		  			{cancel}
		  		</button>
		  		<button
		  			className={`button is-rounded is-danger ${sending ? 'is-loading' : ''}`}
		  			onClick={handleConfirm}
		  		>
		  			{confirm}
		  		</button>		  		
		  	</div>
		  </div>
		</div>
	);
};

export default ConfirmationMessage;