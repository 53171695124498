import React, { useEffect, useRef } from 'react';
import classes from './Notification.module.scss';
import { NOTIFICATION_DURATION } from '../../config';
import { ReactComponent as SuccessIcon } from '../../assets/icons/success.svg';
import { ReactComponent as ErrorIcon } from '../../assets/icons/error.svg';

const Notification = ({ notification, closeNotification }) => {

	const timeoutId = useRef(null);

	useEffect(() => {
		if(timeoutId.current) {
			window.clearTimeout(timeoutId.current);
			timeoutId.current = null;
		}
		if(notification) {
			timeoutId.current = window.setTimeout(closeNotification, NOTIFICATION_DURATION);
		}
	}, [ notification, closeNotification ]);

	const handleNotificationClick = () => {
		if(timeoutId.current) {
			window.clearTimeout(timeoutId.current);
			timeoutId.current = null;
		}
		closeNotification();
	}

	let notificationContent = null;
	if(notification) {
		switch(notification.type) {
			case 'basic':
				notificationContent = <p className={classes.basicText}>{notification.message}</p>;
				break;
			case 'success':
				notificationContent = (
					<div className={classes.successMessage}>
						<SuccessIcon className={classes.successIcon} />
						<p>{notification.message}</p>
					</div>
      	);
      	break;
      case 'error':
				notificationContent = (
					<div className={classes.errorMessage}>
						<ErrorIcon className={classes.errorIcon} />
						<p>{notification.message}</p>
					</div>
      	);
      	break;
      case 'signup':
				notificationContent = (
					<React.Fragment>
						<div className={classes.successMessage}>
							<SuccessIcon className={classes.successIcon} />
							<p>{notification.message[0]}</p>
						</div>
						<p className={classes.basicText}>{notification.message[1]}</p>
					</React.Fragment>
      	);
      	break;
			default:
				break;
		}
	}

	return (
		<div
			className={`${classes.notification} ${notification ? classes.show : ''}`}
			onClick={handleNotificationClick}
		>
			{notificationContent}
		</div>
	);
};

export default Notification;