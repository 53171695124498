import React, { useState, useRef, useEffect } from 'react';
import { navigate } from "@reach/router";
import { withTranslation } from 'react-i18next';
import axios from 'axios';
import classes from './PasswordReset.module.scss';
import { ROUTES } from '../../config';
import logo from '../../assets/logos/greenpin.svg';
import { ReactComponent as SuccessIcon } from '../../assets/icons/success.svg';
import { ReactComponent as ErrorIcon } from '../../assets/icons/error.svg';

const PasswordReset = ({ t, location }) => {

	const code = useRef(null);

	const [ status, setStatus ] = useState(null);
	const [ errorMessage, setErrorMessage ] = useState(null);
	const [ password, setPassword ] = useState('');
	const [ passwordConfirmation, setPasswordConfirmation ] = useState('');

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
  	if(queryParams.has('code')) {
  		code.current = queryParams.get('code');
  	} else {
  		navigate('/');
  	}
	}, [location]);

	const submitPassword = e => {
		e.preventDefault();
		if(status === 'sending') return;
		if(status === 'success') navigate('/');
		if(password !== passwordConfirmation) {
			setStatus('error');
			setErrorMessage(t('passwordReset.errors.notMatch'));
			return;
		}
		setStatus('sending');
		const data = {
	    code: code.current,
	    password,
	    passwordConfirmation
	  };
		axios
		  .post(ROUTES.RESET, data)
		  .then(res => {
		  	setStatus('success');
		  	window.setTimeout(() => navigate('/'), 3000);
		  })
		  .catch(error => {
		  	setStatus('error');
		  	setErrorMessage(t('passwordReset.errors.internalReset'));
		  });
	}

	const buttonText = (status === 'success')
		? t('passwordReset.continue')
		: (status === 'sending') 
			? null
			: t('passwordReset.send');

	return (
		<div className={classes.container}>
			<div className={classes.logoContainer}>
				<span>Greenpin</span>
				<img src={logo} alt='logo' />
			</div>
			<h1 className={classes.header}>{t('passwordReset.header')}</h1>
			{(status === 'success') && (
				<div className={classes.notificationDisplay}>
					<SuccessIcon className={classes.successIcon}/>
					<p className={classes.successMessage}>{t('passwordReset.success')}</p>
				</div>
			)}
			{(status === 'error') && (
				<div className={classes.notificationDisplay}>
					<ErrorIcon className={classes.errorIcon}/>
					<p className={classes.errorMessage}>{errorMessage}</p>
				</div>
			)}
			<form onSubmit={submitPassword}> 
				<div className={`field ${classes.field}`}>
					<input 
						className={`input ${classes.input}`}
						type='password'
						name='password'
						placeholder={t('passwordReset.password')}
						required
						value={password}
						onChange={e => setPassword(e.target.value)}
					/>
				</div>
				<div className={`field ${classes.field}`}>
					<input 
						className={`input ${classes.input}`}
						type='password'
						name='passwordConfirmation'
						placeholder={t('passwordReset.confirm')}
						required
						value={passwordConfirmation}
						onChange={e => setPasswordConfirmation(e.target.value)}
					/>
				</div>
				<button
					type='submit'
					className={`button ${classes.submitButton} ${(status === 'sending') ? 'is-loading' : ''}`}
					disabled={false}
				>
					{buttonText}
				</button>
			</form>
		</div>
	);
};

export default withTranslation()(PasswordReset);