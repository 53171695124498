import React, { useState, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import axios from 'axios';
import classes from './PinEditor.module.scss';
import ConfirmationMessage from '../ConfirmationMessage/ConfirmationMessage';
import { CATEGORIES, MAX_PIN_IMAGE_SIZE, ROUTES, API_URL } from '../../config';
import { ReactComponent as PinIcon } from '../../assets/icons/pin.svg';
import { ReactComponent as ErrorIcon } from '../../assets/icons/error.svg';
import { ReactComponent as ImageIcon } from '../../assets/icons/image.svg';

const reqCategoryIcons = require.context('../../assets/icons/categories', false, /\.svg$/);

const PinEditor = (props) => {

	const {
		editMode,
		coordinates,
		associatedRouteId,
		pinId,
		pin,
		routes,
		setPinRouteAssociationMode,
		setNotification,
		addNewPin,
		updatePin,
		removePin,
		t
	} = props;

	const imageInputRef = useRef(null);

	const [ status, setStatus ] = useState(null);
	const [ errorMessage, setErrorMessage ] = useState(null);
	const [ name, setName ] = useState(editMode ? pin.name : '');
	const [ category, setCategory ] = useState(editMode ? pin.category : '');
	const [ description, setDescription ] = useState(editMode ? pin.description : '');
	const [ imageUrl, setImageUrl ] = useState(editMode ? API_URL + pin.image.url : null);
	const [ imageFile, setImageFile ] = useState(null);
	const [ confirmationMessageShown, setConfirmationMessageShown ] = useState(false);

	const routeId = associatedRouteId
		? associatedRouteId
		: editMode
			? pin.route
			: null;

	const selectImage = e => {
		const file = e.target.files[0];
		if(!file) return;

		// Image file type validation
		if(file.type !== 'image/jpeg' && file.type !== 'image/png') {
			setStatus('invalidImage');
			setErrorMessage(t('pinEditor.errors.imageType'));
			return;
		}
		// Image file size validation
		if(file.size > MAX_PIN_IMAGE_SIZE) {
			setStatus('invalidImage');
			setErrorMessage(t('pinEditor.errors.imageSize'));
			return;
		}

		setStatus(null);

		if(imageUrl && imageFile) {
			URL.revokeObjectURL(imageUrl);
		}
		setImageFile(file);
		setImageUrl(URL.createObjectURL(file));
	}

	const submitPin = e => {
		e.preventDefault();
		if(status === 'sending' || status === 'invalidImage') return;
		if(!editMode && !imageFile) {
			setStatus('invalidImage');
			setErrorMessage(t('pinEditor.errors.imageRequired'));
			return;
		}
		setStatus('sending');

		if(!editMode) {
			let data = {
				name,
				category,
				description,
				geometry: {
					type: 'Point',
					coordinates
				}
			};
			if(associatedRouteId) {
				data.route = associatedRouteId;
			}
			const formData = new FormData();
			formData.append('files.image', imageFile, imageFile.name);
			formData.append('data', JSON.stringify(data));

			axios
			  .post(ROUTES.PINS, formData)
			  .then(res => {
			  	setStatus('success');
			  	const data = {
			  		...res.data,
			  		user: res.data.user.id,
			  		route: res.data.route ? res.data.route.id : null,
			  	};
			  	addNewPin(data);
			  	setNotification({
			  		type: 'success',
			  		message: t('pinEditor.notifications.new')
			  	});
			  })
			  .catch(error => {
			  	setStatus('error');
			  	setErrorMessage(t('pinEditor.errors.internalNew'));
			  });
		}
		else {
			const data = {
				name,
				category,
				description
			};
			if(associatedRouteId) {
				data.route = associatedRouteId;
			}
			let formData = null;
			if(imageFile) {
				formData = new FormData();
				formData.append('files.image', imageFile, imageFile.name);
				formData.append('data', JSON.stringify(data));
			} else {
				formData = data;
			}

			axios
			  .put(ROUTES.PINS + '/' + pinId, formData)
			  .then(res => {
			  	setStatus('success');
			  	const data = {
			  		...res.data,
			  		user: res.data.user.id,
			  		route: res.data.route ? res.data.route.id : null,
			  	};
			  	updatePin(data);
			  	setNotification({
			  		type: 'success',
			  		message: t('pinEditor.notifications.edit')
			  	});
			  })
			  .catch(error => {
			  	setStatus('error');
			  	setErrorMessage(t('pinEditor.errors.internalEdit'));
			  });
		}
	}

	const deletePin = () => {
		if(status === 'sending') return;
		setStatus('sending');

		axios
			  .delete(ROUTES.PINS + '/' + pinId)
			  .then(res => {
			  	setStatus('success');
			  	const data = {
			  		...res.data,
			  		user: res.data.user.id,
			  		route: res.data.route ? res.data.route.id : null,
			  	};
			  	removePin(data);
			  	setNotification({
			  		type: 'success',
			  		message: t('pinEditor.notifications.delete')
			  	});
			  })
			  .catch(error => {
			  	setStatus('error');
			  	setErrorMessage(t('pinEditor.errors.internalDelete'));
			  });
	}

	/* HEADER */
	const header = (
		<div className={classes.headerContainer}>
			<div className={classes.headerIconContainer}>
				<PinIcon className={classes.pinIcon} />
			</div>
			<h1 className={classes.header}>
				{editMode ? t('pinEditor.header.edit') : t('pinEditor.header.new')}
			</h1>
		</div>
	);

	/* ERROR DISPLAY */
	const errorDisplay = (status === 'error' || status === 'invalidImage') ? (
		<div className={classes.errorDisplay}>
			<ErrorIcon className={classes.errorIcon}/>
			<p className={classes.errorMessage}>{errorMessage}</p>
		</div>
	) : null;

	/* FORM */
	const buttons = editMode ? (
		<div className={classes.buttonsContainer}>
			<button
				type='submit'
				className={`button ${classes.primaryButton} ${(status === 'sending') ? 'is-loading' : ''}`}
			>
				{t('pinEditor.button.edit')}
			</button>
			<button
				type='button'
				className={`button ${classes.deleteButton} ${(status === 'sending') ? 'is-loading' : ''}`}
				onClick={() => setConfirmationMessageShown(true)}
			>
				{t('pinEditor.button.delete')}
			</button>
		</div>
	) : (
		<button
			type='submit'
			className={`button ${classes.primaryButton} ${(status === 'sending') ? 'is-loading' : ''}`}
		>
			{editMode ? t('pinEditor.button.edit') : t('pinEditor.button.new')}
		</button>	
	);


	const form = (
		<form onSubmit={submitPin}>
			<div className={`field ${classes.field}`} style={{ textAlign: 'center' }}>
				<div
					className={imageUrl ? classes.imageContainer : classes.imageFrame}
					tabIndex='0'
					onClick={() => imageInputRef.current.click()}
					// onKeyDown={imageInputKeyPressHandler.bind(this, imageName)}
				>
					{imageUrl && (
						<img
							src={imageUrl}
							className={classes.image}
							alt=''
						/>
					)}
					<ImageIcon className={classes.imageIcon}/>
					{!imageUrl && (
						<span className={classes.imageText}>{t('pinEditor.image')}</span>
					)}
					<input 
						name='image'
						type='file'
						accept='.jpg, .jpeg, .png'
						style={{ display: 'none' }} 
						onChange={selectImage}
						ref={imageInputRef}
					/>	
				</div>
			</div>
			<div className={`field ${classes.field}`}>
				<input 
					className={`input ${classes.input}`}
					name='name' 
					type='text'
					placeholder={t('pinEditor.name')}
					required
					value={name}
					onChange={e => setName(e.target.value)}
				/>
			</div>
			<div className={`field ${classes.field}`}>
				<div className={`select ${classes.select}`}>
				  <select
				  	className={category ? classes.withIcon : ''}
				  	required
				  	value={category}
				  	onChange={e => setCategory(e.target.value)}
				  >
				  	<option value="" disabled>
				  		{t('pinEditor.category')}
				  	</option>
				  	{CATEGORIES.map(cat => (
					    <option key={cat} value={cat}>
					    	{t(`categories.${cat}`)}
					    </option>
				  	))}
				  }
				  </select>
				  {category && (
				  	<div className={classes.categoryIconContainer}>
					  	<img
					  		src={reqCategoryIcons(`./${category}.svg`)}
					  		className={classes.categoryIcon}
					  		alt={category}
					  	/>
				  	</div>
				 	)}
				</div>
			</div>
			<div className={`field ${classes.field}`}>
				<textarea 
					className={`textarea ${classes.textarea}`}
					name='description' 
					placeholder={t('pinEditor.description')}
					rows='4'
					required
					value={description}
					onChange={e => setDescription(e.target.value)}
				/>
			</div>
			<div className={`field ${classes.field}`}>
				<button
					className={`button ${routeId ? classes.pinAssociationButton : classes.pinAssociationButtonPlaceholder}`}
					type='button'
					onClick={() => setPinRouteAssociationMode(true)}
				>
					{routeId
						? <React.Fragment>
								<span className={classes.pinAssociationLabel}>{t('pinEditor.route')}</span>
								<span>{routes[routeId].name}</span>
							</React.Fragment>
						: t('pinEditor.association')
					}
				</button>
			</div>
			{buttons}
		</form>
	);

	const confirmationMessage = (
		<ConfirmationMessage
			show={confirmationMessageShown}
			header={t('pinEditor.confirmation.header')}
			text={t('pinEditor.confirmation.text')}
			cancel={t('pinEditor.confirmation.cancel')}
			confirm={t('pinEditor.confirmation.confirm')}
			sending={status === 'sending'}
			handleCancel={() => setConfirmationMessageShown(false)}
			handleConfirm={deletePin}
		/>
	);

	return (
		<React.Fragment>
			{header}
			{errorDisplay}			
			{form}
			{confirmationMessage}
		</React.Fragment>
	);
};

export default withTranslation()(PinEditor);