import React from 'react';
import { withTranslation } from 'react-i18next';
import classes from './Tools.module.scss';
import { MODAL_PAGES } from '../../config';
import { ReactComponent as CheckmarkIcon } from '../../assets/icons/checkmark.svg';
import { ReactComponent as CrossIcon } from '../../assets/icons/cross.svg';
import { ReactComponent as PinIcon } from '../../assets/icons/pin.svg';
import { ReactComponent as RouteIcon } from '../../assets/icons/route.svg';
import { ReactComponent as NewPinIcon } from '../../assets/icons/new-pin.svg';

const Tools = (props) => {

	const {
		pinRouteAssociationMode,
		modalPage,
		newPinToolIsSelected,
		selectedRouteId,
		setModalPage,
		toggleNewPinTool,
		setPinRouteAssociationMode,
		confirmPinRouteAssociation,
		t
	} = props;

	const handleConfirmButton = () => {
		if(selectedRouteId) confirmPinRouteAssociation(selectedRouteId);
	}

	return pinRouteAssociationMode ? (
		<div className={classes.associationToolContainer}>
			<div className={classes.associationToolText}>
				{t('tools.association')}
			</div>
			<div className={classes.associationToolButtons}>
				<button
					className={`button ${selectedRouteId ? classes.associationConfirmButton : classes.associationConfirmButtonDisabled}`}
					onClick={handleConfirmButton}
				>
					<CheckmarkIcon className={classes.associationConfirmIcon}/>
				</button>
				<button
					className={`button ${classes.associationCancelButton}`}
					onClick={() => setPinRouteAssociationMode(false)}
				>
					<CrossIcon className={classes.associationCancelIcon}/>
				</button>
			</div>
		</div>
	) : (
		<React.Fragment>
			<div className={classes.toolsContainer}>
				<button
					className={`button intro-steps-four ${(modalPage === MODAL_PAGES.PINS) ? classes.selectedToolButton : classes.toolButton}`}
					onClick={() => setModalPage(MODAL_PAGES.PINS_MENU, {})}
				>
					<PinIcon className={classes.pinIcon}/>
				</button>
				<span className={classes.pinsTooltip}>{t('tooltips.pins')}</span>
				<button
					className={`button intro-steps-five ${(modalPage === MODAL_PAGES.ROUTES) ? classes.selectedToolButton : classes.toolButton}`}
					onClick={() => setModalPage(MODAL_PAGES.ROUTES_MENU, {})}
				>
					<RouteIcon className={classes.routeIcon}/>
				</button>
				<span className={classes.routesTooltip}>{t('tooltips.routes')}</span>
			</div>
			<div className={classes.newPinToolContainer}>
				<button
					className={`button intro-steps-three ${newPinToolIsSelected ? classes.selectedNewPinTool : classes.newPinTool}`}
					onClick={toggleNewPinTool}
				>
					<NewPinIcon className={classes.newPinIcon} />
				</button>
				<span className={classes.newPinTooltip}>{t('tooltips.newPin')}</span>
			</div>
		</React.Fragment>
	);
};

export default withTranslation()(Tools);