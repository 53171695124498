import React from 'react';
import classes from './Modal.module.scss';

const Modal = ({ show, closeModal, children }) => (
	<div className={show ? classes.modalShown : classes.modal}>
		<div className={classes.modalBackground} onClick={closeModal}></div>
		<div className={classes.modalDialog}>
			<button 
				className={`delete is-medium ${classes.closeButton}`}
				onClick={closeModal}>
			</button>
			{children}
		</div>
	</div>
);

export default Modal;