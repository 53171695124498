import React from 'react';
import classes from './AuthPanel.module.scss';

const AuthPanel = ({ authPanelIsOpen, setAuthPanel, children }) => (
	<div className={authPanelIsOpen ? classes.panelOpen : classes.panel}>
		<button
			className={`delete is-medium ${classes.closeButton}`}
			onClick={setAuthPanel.bind(this, false)}>
		</button>
		{children}
	</div>
);

export default AuthPanel;