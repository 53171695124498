import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import classes from './RoutePopup.module.scss';
import { formatTime } from '../../utils';
import { SITE_URL } from '../../config';
import { ReactComponent as StarIcon } from '../../assets/icons/star.svg';
import { ReactComponent as TwitterIcon } from '../../assets/icons/twitter.svg';
import { ReactComponent as FacebookIcon } from '../../assets/icons/facebook.svg';
import { ReactComponent as LinkIcon } from '../../assets/icons/link.svg';

const RoutePopup = (props) => {

	const [ linkCopied, setLinkCopied ] = useState(false);

	const {
		show,
		route,
		userRoutes,
		selectRoute,
		saveRoute,
		t
	} = props;

	let popupContent = null;
	if(show) {
		const routeUrl = `${SITE_URL}?route=${route.id}`;
		
		const twitterText = `${t('socialMedia.route')} ${route.name}`;
		const twitterIntentHref = `https://twitter.com/intent/tweet?url=${routeUrl}&text=${encodeURIComponent(twitterText)}&hashtags=greenpin`;
		
		const shareOnFacebook = () => {
	    window.FB.ui({
	      method: 'share',
	      href: routeUrl,
	      hashtag: '#greenpin'
	    }, function(response){});
	  }

	  const copyRouteUrl = () => {
			setLinkCopied(true);
			window.setTimeout(() => setLinkCopied(false), 450);
			const el = document.createElement('input');
	    el.value = routeUrl;
	    el.className = classes.hiddenUrlInput;
	    document.body.appendChild(el);
	    el.select();
	    document.execCommand("copy");
	    el.remove();
		}
		
		const isRouteSaved = userRoutes && userRoutes.includes(route.id);
		
		popupContent = (
			<React.Fragment>
				<button 
					className={`delete is-medium ${classes.closeButton}`}
					onClick={() => selectRoute(null)}>
				</button>
				<StarIcon
					className={isRouteSaved ? classes.starSaved : classes.star}
					onClick={saveRoute}
				/>
				<div className={classes.dataContainer}>
					<h1 className={classes.header}>
						{route.name}
					</h1>
					<div className={classes.details}>
						<span>{`${route.distance} ${t('common.km')}`}</span>
						<span>{`${formatTime(route.time)} ${t('common.hours')}`}</span>
						<span>{`${route.slope} ${t('common.slope')}`}</span>
					</div>
					<div className={classes.socialMedia}>
						<a
							href={twitterIntentHref}
							className={classes.twitterContainer}
							target='_blank'
							rel='noopener noreferrer'
						>
							<TwitterIcon className={classes.socialMediaIcon}/>
						</a>
						<div
							className={classes.facebookContainer}
							onClick={shareOnFacebook}
						>
							<FacebookIcon className={classes.socialMediaIcon} />
						</div>
						<div className={classes.linkContainer}>
							<LinkIcon
								className={linkCopied ? classes.copiedLinkIcon : classes.linkIcon}
								onClick={copyRouteUrl}
							/>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}

	return (
		<div className={show ? classes.popupShown : classes.popup}>
			{popupContent}
		</div>
	);
};

export default withTranslation()(RoutePopup);