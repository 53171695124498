import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { Steps } from 'intro.js-react';
import { calculateRank } from '../../utils';
import { API_URL, INITIAL_STYLE, ROUTES, MODAL_PAGES, CATEGORIES, INTRO_STEPS, ROUTE_FILTER_MAX_DISTANCE, ROUTE_FILTER_MAX_TIME } from '../../config';
import Map from '../Map/Map';
import Header from '../Header/Header';
import Auth from '../Auth/Auth';
import AuthPanel from '../Auth/AuthPanel';
import MapStyleButton from '../MapStyleButton/MapStyleButton';
import Notification from '../Notification/Notification';
import Tools from '../Tools/Tools';
import Modal from '../Modal/Modal';
import PinEditor from '../Pins/PinEditor';
import PinsMenu from '../Pins/PinsMenu';
import RoutesMenu from '../Routes/RoutesMenu';
import PinPopup from '../Pins/PinPopup';
import RoutePopup from '../Routes/RoutePopup';
import RoutesFilters from '../Routes/RoutesFilters';
import UserPanel from '../UserPanel/UserPanel';
import Intro from '../Intro/Intro';
import Rules from '../Rules/Rules';

axios.defaults.baseURL = API_URL;

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthenticated: false,
      currentUser: null,
      currentMapStyle: INITIAL_STYLE,
      routes: null,
      pins: null,
      authPanelIsOpen: false,
      notification: null,
      modalPage: {
        key: null,
        data: null
      },
      pinRouteAssociationMode: false,
      newPinToolIsSelected: false,
      selectedPinCategories: CATEGORIES,
      selectedRouteId: null,
      selectedPinId: null,
      routesFiltersOpen: false,
      routesFilters: {
        distance: { min: 0, max: ROUTE_FILTER_MAX_DISTANCE },
        time: { min: 0, max: ROUTE_FILTER_MAX_TIME }
      },
      introPageShown: false,
      introStepsShown: false
    };
    this.mapRef = React.createRef();
  }

  componentDidMount() {
    this.handleIntro();
    this.checkAuthState();
    this.loadRoutes();
    this.loadPins();
  }

  checkAuthState = async () => {
    const jwtToken = localStorage.getItem('jwtToken');
    if(jwtToken) {
      // Try-Catch to prevent someone from manually tampering with the token in Local Storage
      try {
        jwtDecode(jwtToken);
        this.setAuthTokenHeader(jwtToken);
        let currentUser = await this.getCurrentUserData();
        if(currentUser) {
          this.setAuthenticated(currentUser);
          this.setNotification({
            type: 'basic',
            message: this.props.t('auth.notifications.login') + currentUser.username + '!'
          });
        } else {
          this.logout();
        }
      } 
      catch(error) {
        this.logout();
      }
    }
  }

  setAuthTokenHeader = token => {
    // when user logs in
    if(token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } 
    // when user logs out (token = false)
    else {
      delete axios.defaults.headers.common['Authorization'];
    } 
  }

  getCurrentUserData = async () => {
    try {
      let res = await axios.get(ROUTES.ME);
      return {
        id: res.data.id,
        username: res.data.username,
        rank: calculateRank(res.data.pins.length),
        routes: res.data.routes,
        pins: res.data.pins
      };
    }
    catch(error) {
      return null;
    }
  }

  setAuthenticated = currentUser => {
    if(currentUser) {
      this.setState({ isAuthenticated: true, currentUser });
    } else {
      this.setState({
        isAuthenticated: false,
        currentUser: null,
        newPinToolIsSelected: false,
        modalPage: { key: null, data: null }
      });
    }
  }

  logout = () => {
    this.setAuthTokenHeader();
    localStorage.removeItem('jwtToken');
    this.setAuthenticated(null);
  }

  handleIntro = () => {
    try {
      const introShown = localStorage.getItem('introShown');
      if(!introShown) {
        this.showIntroPage();
      }
    } catch (err) {}
  }

  loadRoutes = async () => {
    try {
      let res = await axios.get(ROUTES.ROUTES);
      const routes = res.data.reduce((obj, route) => {
        obj[route.id] = route;
        return obj;
      }, {});
      this.setState({ routes });
    }
    catch(error) {
      this.setNotification({
        type: 'error',
        message: this.props.t('app.notifications.errorLoad')
      });
    }
  }

  loadPins = async () => {
    try {
      let res = await axios.get(ROUTES.PINS);
      const pins = res.data.reduce((obj, pin) => {
        obj[pin.id] = pin;
        return obj;
      }, {});
      this.setState({ pins });
    }
    catch(error) {
      this.setNotification({
        type: 'error',
        message: this.props.t('app.notifications.errorLoad')
      });
    }
  }

  onFinishMapLoading = () => {
    if(window.location.search) {
      const routeId = Number(new URLSearchParams(document.location.search).get('route'));
      const pinId = Number(new URLSearchParams(document.location.search).get('pin'));
      window.history.pushState({}, document.title, window.location.pathname);
      if(routeId && !isNaN(routeId) && this.state.routes[routeId]) {
        this.selectRoute(routeId);
      }
      else if(pinId && !isNaN(pinId) && this.state.pins[pinId]) {
        this.selectPin(pinId);
      }
    }
  }

  selectRoute = routeId => {
    this.setState(state => {
      let updatedState = {
        selectedRouteId: routeId,
        selectedPinId: null
      };
      if(!state.pinRouteAssociationMode) {
        updatedState.modalPage = { key: null, data: null };
      }
      return { ...updatedState };
    });
  }

  selectPin = pinId => {
    this.setState({
      selectedPinId: pinId,
      selectedRouteId: null,
      modalPage: { key: null, data: null }
    });
    if(pinId) this.mapRef.current.shiftMapToCenterPin(this.state.pins[pinId].geometry.coordinates);
  }

  changeMapStyle = (newMapStyle) => {
    this.setState({ currentMapStyle: newMapStyle });
  }

  setAuthPanel = (open) => {
    this.setState({
      authPanelIsOpen: open,
      modalPage: { key: null, data: null }
    });
  }

  setNotification = notification => {
    this.setState({ notification });
  }

  closeNotification = () => {
    this.setState({ notification: null });
  }

  setModalPage = (key, data) => {
    this.setState(state => {
      if(state.modalPage.key === MODAL_PAGES.NEW_PIN) {
        this.mapRef.current.newPinMarker.remove();
      }
      return {
        modalPage: { key, data },
        selectedPinId: null,
        selectedRouteId: null,
        authPanelIsOpen: false,
        routesFiltersOpen: false
      };
    })
  }

  toggleNewPinTool = () => {
    this.setState(state => {
      if(!state.isAuthenticated && !state.newPinToolIsSelected) {
        return { authPanelIsOpen: true };
      } else {
        return { newPinToolIsSelected: !state.newPinToolIsSelected };
      }
    });
  }

  setPinRouteAssociationMode = pinRouteAssociationMode => {
    this.setState({
      pinRouteAssociationMode,
      selectedRouteId: null
    });
  }

  confirmPinRouteAssociation = routeId => {
    this.setState(state => {
      const updatedModalPage = {
        ...state.modalPage,
        data: {
          ...state.modalPage.data,
          associatedRouteId: routeId
        }
      };
      return {
        pinRouteAssociationMode: false,
        modalPage: updatedModalPage
      };
    });
  }

  addNewPin = (data) => {
    this.setModalPage(null, null);

    this.setState(state => {
      let updatedState = {
        pins: {
          ...state.pins,
          [data.id]: data
        },
        currentUser: {
          ...state.currentUser,
          pins: [ ...state.currentUser.pins, data.id ],
          rank: calculateRank(state.currentUser.pins.length + 1),
        }
      };
      if(data.route) {
        updatedState.routes = {
          ...state.routes,
          [data.route]: {
            ...state.routes[data.route],
            pins: [ ...state.routes[data.route].pins, data.id ]
          }
        };
      }
      return updatedState;
    });

    this.mapRef.current.newPinMarker.remove();
  }

  updatePin = (data) => {
    this.setModalPage(null, null);
    this.setState(state => {
      let updatedState = {
        pins: {
          ...state.pins,
          [data.id]: data
        }
      };
      const routeId = data.route;
      const oldRouteId = state.pins[data.id].route;
      if(routeId !== oldRouteId) {
        updatedState.routes = { ...state.routes };
        if(routeId) {
          updatedState.routes[routeId] = {
            ...updatedState.routes[routeId],
            pins: [ ...updatedState.routes[routeId].pins, data.id ]
          };
        }
        if(oldRouteId) {
          updatedState.routes[oldRouteId] = {
            ...updatedState.routes[oldRouteId],
            pins: updatedState.routes[oldRouteId].pins.filter(pinId => pinId !== data.id)
          };
        }
      }
      return { ...updatedState };
    });
  }

  removePin = data => {
    this.setModalPage(null, null);

    this.setState(state => {
      const updatedPins = { ...state.pins };
      delete updatedPins[data.id];
      let updatedState = {
        pins: updatedPins,
        currentUser: {
          ...state.currentUser,
          pins: state.currentUser.pins.filter(pinId => pinId !== data.id),
          rank: calculateRank(state.currentUser.pins.length - 1),
        }
      };
      if(data.route) {
        updatedState.routes = {
          ...state.routes,
          [data.route]: {
            ...state.routes[data.route],
            pins: state.routes[data.route].pins.filter(pinId => pinId !== data.id)
          }
        };
      }
      return updatedState;
    });
  }

  setSelectedPinCategories = selectedCategories => {
    this.setState({
      selectedPinCategories: (selectedCategories.length === 0) ? CATEGORIES : selectedCategories,
      modalPage: { key: null, data: null }
    });
  }

  toggleRoutesFilters = () => {
    this.setState(state => ({ routesFiltersOpen: !state.routesFiltersOpen }));
  }

  setRoutesFilters = (filterKey, filterValue) => {
    this.setState(state => ({
      routesFilters: {
        ...state.routesFilters,
        [filterKey]: filterValue
      }
    }));
  }

  resetRoutesFilters = () => {
    this.setState({ routesFilters: {
      distance: { min: 0, max: ROUTE_FILTER_MAX_DISTANCE },
      time: { min: 0, max: ROUTE_FILTER_MAX_TIME }
    }});
  }

  saveRoute = () => {
    if(!this.state.isAuthenticated) {
      this.setState({ authPanelIsOpen: true });
    }
    else if(this.state.selectedRouteId) {
      axios
        .put(ROUTES.SAVE_ROUTE, { routeId: this.state.selectedRouteId })
        .then(res => {
          this.setState(state => ({
            currentUser: {
              ...state.currentUser,
              routes: res.data
            }
          }));
        })
        .catch(error => {
          // App Notification ??
        });
    }
  }

  showIntroPage = () => {
    this.setState({ introPageShown: true });
  }

  showIntroSteps = () => {
    localStorage.setItem('introShown', true);
    this.setState({
      introPageShown: false,
      introStepsShown: true
    });
  }

  closeIntroSteps = () => {
    this.setState({ introStepsShown: false });
  }

  render() {
    const { t } = this.props;
    const {
      isAuthenticated,
      currentUser,
      currentMapStyle,
      routes,
      pins,
      authPanelIsOpen,
      notification,
      modalPage,
      newPinToolIsSelected,
      pinRouteAssociationMode,
      selectedPinCategories,
      selectedRouteId,
      selectedPinId,
      routesFiltersOpen,
      routesFilters,
      introPageShown,
      introStepsShown
    } = this.state;

    const introSteps = INTRO_STEPS.map(step => ({ ...step, intro: t(step.intro) }));

    let modalPageContent = null;
    switch(modalPage.key) {
      case MODAL_PAGES.NEW_PIN:
      case MODAL_PAGES.EDIT_PIN:
        modalPageContent = (
          <PinEditor
            editMode={modalPage.key === MODAL_PAGES.EDIT_PIN}
            { ...modalPage.data }
            pin={(modalPage.key === MODAL_PAGES.EDIT_PIN) ? pins[modalPage.data.pinId] : null}
            routes={routes}
            setPinRouteAssociationMode={this.setPinRouteAssociationMode}
            setNotification={this.setNotification}
            addNewPin={this.addNewPin}
            updatePin={this.updatePin}
            removePin={this.removePin}
          />
        );
        break;
      case MODAL_PAGES.PINS_MENU:
        modalPageContent = (
          <PinsMenu
            selectedPinCategories={selectedPinCategories}
            setSelectedPinCategories={this.setSelectedPinCategories}
          />
        );
        break;
      case MODAL_PAGES.ROUTES_MENU:
        modalPageContent = (
          <RoutesMenu
            routes={routes}
            routesFilters={routesFilters}
            selectRoute={this.selectRoute}
          />
        );
        break;
      case MODAL_PAGES.USER_PANEL:
        modalPageContent = (
          <UserPanel
            currentUser={currentUser}
            routes={routes}
            pins={pins}
            selectRoute={this.selectRoute}
            selectPin={this.selectPin}
            logout={this.logout}
            setNotification={this.setNotification}
            closeModal={this.setModalPage.bind(this, null, null)}
          />
        );
        break;
      case MODAL_PAGES.RULES:
        modalPageContent = (
          <Rules />
        );
        break;
      default:
        break;
    }

    return (
      <Map
        ref={this.mapRef}
        currentMapStyle={currentMapStyle}
        routes={routes}
        pins={pins}
        pinRouteAssociationMode={pinRouteAssociationMode}
        selectedRouteId={selectedRouteId}
        selectedPinId={selectedPinId}
        selectedPinCategories={selectedPinCategories}
        newPinToolIsSelected={newPinToolIsSelected}
        routesFilters={routesFilters}
        onFinishMapLoading={this.onFinishMapLoading}
        toggleNewPinTool={this.toggleNewPinTool}
        setModalPage={this.setModalPage}
        selectRoute={this.selectRoute}
        selectPin={this.selectPin}
      >
      	<Header
          isAuthenticated={isAuthenticated}
          currentUser={currentUser}
          setAuthPanel={this.setAuthPanel}
          setModalPage={this.setModalPage}
          showIntro={this.showIntroPage}
          logout={this.logout}
        />
        <MapStyleButton
          changeMapStyle={this.changeMapStyle}
          currentMapStyle={currentMapStyle}
        />
        <AuthPanel
          setAuthPanel={this.setAuthPanel}
          authPanelIsOpen={authPanelIsOpen}
        >
          {authPanelIsOpen && (
            <Auth
              setAuthTokenHeader={this.setAuthTokenHeader}
              setAuthenticated={this.setAuthenticated}
              setAuthPanel={this.setAuthPanel}
              setNotification={this.setNotification}
            />
          )}
        </AuthPanel>
        <Notification
          notification={notification}
          closeNotification={this.closeNotification}
        />
        <Tools
          pinRouteAssociationMode={pinRouteAssociationMode}
          modalPage={modalPage}
          newPinToolIsSelected={newPinToolIsSelected}
          selectedRouteId={selectedRouteId}
          setModalPage={this.setModalPage}
          toggleNewPinTool={this.toggleNewPinTool}
          setPinRouteAssociationMode={this.setPinRouteAssociationMode}
          confirmPinRouteAssociation={this.confirmPinRouteAssociation}
        />
        <Modal
          show={modalPage.key !== null && !pinRouteAssociationMode}
          closeModal={this.setModalPage.bind(this, null, null)}
        >
          {modalPageContent}
        </Modal>
        <PinPopup
          show={!!selectedPinId}
          pin={selectedPinId ? pins[selectedPinId] : null}
          currentUserId={currentUser ? currentUser.id : null}
          setModalPage={this.setModalPage}
          selectPin={this.selectPin}
        />
        <RoutePopup
          show={!!selectedRouteId}
          route={selectedRouteId ? routes[selectedRouteId] : null}
          userRoutes={currentUser ? currentUser.routes : null}
          selectRoute={this.selectRoute}
          saveRoute={this.saveRoute}
        />
        <RoutesFilters
          pinRouteAssociationMode={pinRouteAssociationMode}
          routesFiltersOpen={routesFiltersOpen}
          routesFilters={routesFilters}
          toggleRoutesFilters={this.toggleRoutesFilters}
          setRoutesFilters={this.setRoutesFilters}
          resetRoutesFilters={this.resetRoutesFilters}
        />
        <Intro
          show={introPageShown}
          showIntroSteps={this.showIntroSteps}
        />
        <Steps
          enabled={introStepsShown}
          steps={introSteps}
          initialStep={0}
          onExit={this.closeIntroSteps}
          options={{
            nextLabel: t('introSteps.options.next'),
            prevLabel: t('introSteps.options.prev'),
            skipLabel: t('introSteps.options.skip'),
            doneLabel: t('introSteps.options.done')
          }}
        />
      </Map>
    );
  }
}

export default withTranslation()(Main);