import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import axios from 'axios';
import classes from './Auth.module.scss';
import { calculateRank } from '../../utils';
import { SITE_URL, ROUTES, AUTH_ERRORS } from '../../config';
import { ReactComponent as UserIcon } from '../../assets/icons/user.svg';
import { ReactComponent as EmailIcon } from '../../assets/icons/email.svg';
import { ReactComponent as PasswordIcon } from '../../assets/icons/password.svg';
import { ReactComponent as ErrorIcon } from '../../assets/icons/error.svg';

const Auth = (props) => {

	const [ authType, setAuthType ] = useState('login');
	const [ status, setStatus ] = useState(null);
	const [ errorMessage, setErrorMessage ] = useState(null);
	const [ username, setUsername ] = useState('');
	const [ email, setEmail ] = useState('');
	const [ password, setPassword ] = useState('');
	const [ conditions, setConditions ] = useState(false);

	const {
		setAuthTokenHeader,
		setAuthenticated,
		setAuthPanel,
		setNotification,
		t
	} = props;

	const changeAuthType = (type) => {
		if(status === 'error') {
			setStatus(null);
			setErrorMessage(null);
		}
		setUsername('');
		setEmail('');
		setPassword('');
		setAuthType(type);
	}

	const submitAuth = e => {
		e.preventDefault();
		if(status === 'sending' || status === 'success') return;
		setStatus('sending');
		setPassword('');
		if(authType === 'login') {
			const data = {
				identifier: email,
				password
			};
			axios
			  .post(ROUTES.LOGIN, data)
			  .then(res => {
			  	setStatus('success');
			  	const { user, jwt } = res.data;
			  	const currentUser = {
			  		id: user.id,
          	username: user.username,
          	rank: calculateRank(user.pins.length),
        		routes: user.routes.map(route => route.id),
        		pins: user.pins.map(pin => pin.id)
			  	};
			  	localStorage.setItem('jwtToken', jwt);
			  	setAuthTokenHeader(jwt);
			  	setAuthenticated(currentUser);
			  	setAuthPanel(false);
			  	setNotification({
			  		type: 'basic',
			  		message: t('auth.notifications.login') + currentUser.username + '!'
			  	});
			  })
			  .catch(error => {
			  	const errorId = error.response.data.message[0].messages[0].id;
			  	setStatus('error');
			  	let errorMessage = null;
			  	switch(errorId) {
			  		case AUTH_ERRORS.EMAIL_PASSWORD_INVALID:
			  			errorMessage = t('auth.errors.emailPasswordInvalid');
			  			break;
			  		case AUTH_ERRORS.ACCOUNT_BLOCKED:
			  			errorMessage = t('auth.errors.accountBlocked');
			  			break;
			  		default:
			  			errorMessage = t('auth.errors.internalLogin');
			  			break;
			  	}
			  	setErrorMessage(errorMessage);
			  });
		}
		else if(authType === 'signup') {
			const data = {
				username,
				email,
				password
			};
			axios
			  .post(ROUTES.REGISTER, data)
			  .then(res => {
			  	setStatus('success');
			  	const { user, jwt } = res.data;
			  	const currentUser = {
			  		id: user.id,
          	username: user.username,
          	rank: 'bronze',
        		routes: [],
        		pins: []
			  	};
			  	localStorage.setItem('jwtToken', jwt);
			  	setAuthTokenHeader(jwt);
			  	setAuthenticated(currentUser);
			  	setAuthPanel(false);
			  	setNotification({
			  		type: 'signup',
			  		message: [ t('auth.notifications.signup'), t('auth.notifications.login') + currentUser.username + '!' ]
			  	});
			  })
			  .catch(error => {
					const errorId = error.response.data.message[0].messages[0].id;
			  	setStatus('error');
			  	let errorMessage = null;
			  	switch(errorId) {
			  		case AUTH_ERRORS.EMAIL_TAKEN:
			  			errorMessage = t('auth.errors.emailTaken');
			  			break;
			  		case AUTH_ERRORS.USERNAME_TAKEN:
			  			errorMessage = t('auth.errors.usernameTaken');
			  			break;
			  		default:
			  			errorMessage = t('auth.errors.internalSignup');
			  			break;
			  	}
			  	setErrorMessage(errorMessage);
			  });
		}
		else {
			const data = {
				email,
				url: SITE_URL + '/reset-password'
			};
			axios
			  .post(ROUTES.FORGOT, data)
			  .then(res => {
			  	setStatus('success');
			  	setNotification({
			  		type: 'success',
			  		message: t('auth.notifications.forgot')
			  	});
			  })
			  .catch(error => {
			  	const errorId = error.response.data.message[0].messages[0].id;
			  	setStatus('error');
			  	let errorMessage = null;
			  	switch(errorId) {
			  		case AUTH_ERRORS.EMAIL_NOT_EXIST:
			  			errorMessage = t('auth.errors.emailNotExist');
			  			break;
			  		default:
			  			errorMessage = t('auth.errors.internalForgot');
			  			break;
			  	}
			  	setErrorMessage(errorMessage);
			  });
		}
	}

	/* HEADER */
	const headerText = (authType === 'signup') ? t('auth.signupHeader')
		: (authType === 'login') ? t('auth.loginHeader')
		: t('auth.forgotHeader');

	const header = (
		<React.Fragment>
			<h1 className={classes.header}>{headerText}</h1>
		</React.Fragment>
	);

	/* ERROR DISPLAY */
	const errorDisplay = (status === 'error') ? (
		<div className={classes.errorDisplay}>
			<ErrorIcon className={classes.errorIcon}/>
			<p className={classes.errorMessage}>{errorMessage}</p>
		</div>
	) : null;

	/* FORM */
	const submitButtonText = (status === 'sending') ? null
		: (authType === 'signup') ? t('auth.signupButton')
		: (authType === 'login') ?	t('auth.loginButton')
		: t('auth.forgotButton');

	const form = (
		<form onSubmit={submitAuth}> 
			{(authType === 'signup') && (
				<div className={`field ${classes.field}`}>
					<div className='control has-icons-left'>
						<input 
							className={`input ${classes.input}`}
							name='username' 
							type='text'
							placeholder={t('auth.username')}
							required
							value={username}
							onChange={e => setUsername(e.target.value)}
						/>
						<span className={`icon is-small is-left ${classes.inputIconContainer}`}>
			      	<UserIcon className={classes.inputIcon}/>
			    	</span>
					</div>
				</div>
			)}
			<div className={`field ${classes.field}`}>
				<div className='control has-icons-left'>
					<input 
						className={`input ${classes.input}`}
						name='email' 
						type={(authType === 'login') ? 'text' : 'email'}
						placeholder={(authType === 'login') ? t('auth.emailOrUsername') : t('auth.email')}
						required
						value={email}
						onChange={e => setEmail(e.target.value)}
					/>
					<span className={`icon is-small is-left ${classes.inputIconContainer}`}>
		      	<EmailIcon className={classes.inputIcon}/>
		    	</span>
				</div>
			</div>
			{(authType === 'signup' || authType === 'login') && (
				<div className={`field ${classes.field}`}>
					<div className='control has-icons-left'>
						<input 
							className={`input ${classes.input}`}
							type='password'
							name='password'
							placeholder={t('auth.password')}
							required
							value={password}
							onChange={e => setPassword(e.target.value)}
						/>
						<span className={`icon is-small is-left ${classes.inputIconContainer}`}>
			      	<PasswordIcon className={classes.inputIcon}/>
			    	</span>							
					</div>
				</div>
			)}
			{(authType === 'signup') && (
				<div className={classes.conditions}>
					<label className={`checkbox`}>
						<input
							name='accept'
							type='checkbox'
							required
							checked={conditions}
							onChange={e => setConditions(e.target.checked)}
						/>
						<span>
							{t('auth.accept')}
							<a href='/politica-privacidad' target="_blank" rel="noopener noreferrer">
								{t('auth.conditions')}
							</a>
						</span>
					</label>
				</div>
			)}
			<button
				type='submit'
				className={`button ${classes.primaryButton} ${(status === 'sending') ? 'is-loading' : ''}`}
				disabled={false}
			>
				{submitButtonText}
			</button>
		</form>
	);
	
	/* LINKS */
	const links = (authType === 'login') ? (
		<div className={classes.linksContainer}>
			<p
				className={classes.link}
				tabIndex='0'
				onClick={() => changeAuthType('signup')}
			>
				{t('auth.signupLink')}
			</p>
			<p
				className={classes.link}
				tabIndex='0'
				onClick={() => changeAuthType('forgot')}
			>
				{t('auth.forgotLink')}
			</p>
		</div>
	) : (authType === 'signup') ? (
		<div className={classes.linksContainer}>
			<p
				className={classes.link}
				tabIndex='0'
				onClick={() => changeAuthType('login')}
			>
				{t('auth.loginLink')}
			</p>
		</div>
	) : null;

	return (
		<div>
			{header}
			{errorDisplay}
			{form}
			{links}
		</div>
	);
};

export default withTranslation()(Auth);