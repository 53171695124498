import React from 'react';
import { withTranslation } from 'react-i18next';
import classes from './Rules.module.scss';
import { ReactComponent as LeafIcon } from '../../assets/logos/greenpin.svg';

const Rules = ({ t }) => (
	<React.Fragment>
		<div className={classes.headerContainer}>
			<h1 className={classes.header}>
				{t('rules.header')}
			</h1>
		</div>
		<div className={classes.rule}>
			<LeafIcon className={classes.leafIcon} />
			<p>{t('rules.rule1')}</p>
		</div>
		<div className={classes.rule}>
			<LeafIcon className={classes.leafIcon} />
			<p>{t('rules.rule2')}</p>
		</div>
		<div className={classes.rule}>
			<LeafIcon className={classes.leafIcon} />
			<p>{t('rules.rule3')}</p>
		</div>
		<div className={classes.rule}>
			<LeafIcon className={classes.leafIcon} />
			<p>{t('rules.rule4')}</p>
		</div>
		<div className={classes.rule}>
			<LeafIcon className={classes.leafIcon} />
			<p>{t('rules.rule5')}</p>
		</div>
	</React.Fragment>
);

export default withTranslation()(Rules);