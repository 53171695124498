import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import classes from './PinsMenu.module.scss';
import { CATEGORIES } from '../../config';
import { ReactComponent as PinIcon } from '../../assets/icons/pin.svg';
import { ReactComponent as CheckmarkIcon } from '../../assets/icons/checkmark.svg';

const reqCategoryIcons = require.context('../../assets/icons/categories', false, /\.svg$/);
const categories = CATEGORIES.map(category => ({
  name: category,
  icon: reqCategoryIcons(`./${category}.svg`)
}));

const PinsMenu = (props) => {

	const {
		selectedPinCategories,
		setSelectedPinCategories,
		t
	} = props;

	const initializeSelectedCategories = () => (selectedPinCategories.length === CATEGORIES.length)
		? []
		: selectedPinCategories;

	const [ selectedCategories, setSelectedCategories ] = useState(initializeSelectedCategories);

	const toggleCategory = e => {
		const category = e.target.name;
		if(selectedCategories.includes(category)) {
			setSelectedCategories(selectedCategories.filter(cat => cat !== category));
		} else {
			setSelectedCategories([...selectedCategories, category]);
		}
	}

	return (
		<React.Fragment>
			<div className={classes.headerContainer}>
				<div className={classes.headerIconContainer}>
					<PinIcon className={classes.pinIcon} />
				</div>
				<h1 className={classes.header}>
					{t('pinsMenu.header')}
				</h1>
			</div>
			<ul className={classes.categoriesList}>
				{categories.map(category => {
					const selected = selectedCategories.includes(category.name);
					return (
						<li key={category.name} className={classes.categoryRow}>
							<div className={selected ? classes.checkedCheckboxContainer : classes.checkboxContainer}>
								<input
									type='checkbox'
									name={category.name}
									className={classes.checkbox}
									checked={selected}
									onChange={toggleCategory}
								/>
								<CheckmarkIcon className={classes.checkmark} />
							</div>
							<span
								className={classes.categoryText}
							>
								{t(`categories.${category.name}`)}
							</span>
							<img
					  		src={category.icon}
					  		className={classes.categoryIcon}
					  		alt={category.name}
					  	/>
						</li>
					);
				})}
			</ul>
			<div className={classes.buttonsContainer}>
				<button
					className={`button ${classes.primaryButton}`}
					onClick={() => setSelectedPinCategories(selectedCategories)}
				>
					{t('pinsMenu.select')}
				</button>
				<button
					className={`button ${classes.secondaryButton}`}
					onClick={() => setSelectedPinCategories([])}
				>
					{t('pinsMenu.reset')}
				</button>
			</div>
		</React.Fragment>
	);
};

export default withTranslation()(PinsMenu);