import React, { Component } from 'react';
import mapboxgl from 'mapbox-gl';
import { MAPBOX_ACCESS_TOKEN, INITIAL_MAP, STYLES, MODAL_PAGES, CATEGORIES } from '../../config';
import pinIcon from '../../assets/icons/pin.png';
import pinIconHi from '../../assets/icons/pin-hi.png';

mapboxgl.accessToken = MAPBOX_ACCESS_TOKEN;

const reqCategoryIcons = require.context('../../assets/icons/categories', false, /\.png$/);
const categoryIcons = CATEGORIES.map(category => ({
  name: category,
  icon: reqCategoryIcons(`./${category}.png`)
}));

class Map extends Component {

  constructor(props) {
    super(props);
    this.state = {
      routesLoadedOnMap: false,
      pinsLoadedOnMap: false
    };
    this.mapContainer = React.createRef();
    this.map = null;
    this.newPinMarker = null;
  }

  componentDidMount() {
    this.map = new mapboxgl.Map({
      container: this.mapContainer.current,
      ...INITIAL_MAP
    });

    this.map.addControl(new mapboxgl.AttributionControl({ customAttribution: ['Developed by <a href="https://cartometrics.com" target="_blank"><strong>Cartometrics</strong></a>'] }), 'bottom-right');
    this.map.addControl(new mapboxgl.NavigationControl({showCompass: false}), 'top-right');

    // Create new pin marker
    const el = document.createElement('div');
    el.style.backgroundImage = `url('data:image/svg+xml;utf8,<svg version="1.1" width="20" height="27" viewBox="0 0 44 60" xmlns="http://www.w3.org/2000/svg"><polygon class="fillColored" transform="translate(-8)" points="10 22 10 4 52 23.5 10 40" fill="%23018100"/><path class="fillColored" d="m1 0c-0.552 0-1 0.447-1 1v58c0 0.553 0.448 1 1 1s1-0.447 1-1v-58c0-0.553-0.448-1-1-1z" fill="%23424a60"/></svg>')`;
    el.style.width = '20px';
    el.style.height = '27px';
    this.newPinMarker = new mapboxgl.Marker(el, { anchor: 'bottom-left' });
  }

  componentDidUpdate(prevProps) {
    if(this.props.currentMapStyle !== prevProps.currentMapStyle) {
      this.map.setStyle(STYLES[this.props.currentMapStyle]);
      this.map.once('styledata', this.loadPinsOnMap);
      this.map.once('styledata', this.loadRoutesOnMap);
    }

    if(this.props.newPinToolIsSelected !== prevProps.newPinToolIsSelected) {
      if(this.props.newPinToolIsSelected) {
        this.map.getCanvas().style.cursor = `url(${pinIcon}) 0 26, auto`;
        this.map.getCanvas().style.cursor = `-webkit-image-set(url(${pinIcon}) 1x, url(${pinIconHi}) 2x) 0 26, auto`;
        this.map.on('click', this.setNewPin);
      } else {
        this.map.getCanvas().style.cursor = 'inherit';
        this.map.off('click', this.setNewPin);
      }
    }

    if(this.props.routes && !prevProps.routes) {
      if(this.map.loaded()) {
        this.loadRoutesOnMap();
      }
      else {
        this.map.on('load', this.loadRoutesOnMap);
      }
    }

    if(this.props.pins !== prevProps.pins) {
      if(prevProps.pins) {
        this.updatePinsOnMap();
      }
      else if(this.map.loaded()) {
        this.loadPinsOnMap();
      }
      else {
        this.map.on('load', this.loadPinsOnMap);
      }
    }

    if(this.props.selectedPinCategories !== prevProps.selectedPinCategories) {
      const { selectedPinCategories, routes, selectedRouteId } = this.props;
      this.map.setFilter('pins', this.generatePinsFilter(selectedPinCategories, routes, selectedRouteId));
    }

    if(this.props.selectedRouteId !== prevProps.selectedRouteId) {
      const { routes, selectedRouteId, selectedPinCategories } = this.props;
      if(!selectedRouteId) {
        this.map.setPaintProperty('routes', 'line-width', 3);
        this.map.setFilter('routes', this.generateRoutesFilter(this.props.routesFilters, this.props.selectedRouteId));  
        this.map.setFilter('routes-labels', this.generateRoutesFilter(this.props.routesFilters, this.props.selectedRouteId));  
        this.map.setFilter('pins', this.generatePinsFilter(selectedPinCategories, routes, null));  
      }
      else {
        this.map.setPaintProperty('routes', 'line-width', ['match', ['get', 'id'], selectedRouteId, 4, 3]);
        this.map.setFilter('routes', this.generateRoutesFilter(this.props.routesFilters, this.props.selectedRouteId));  
        this.map.setFilter('routes-labels', this.generateRoutesFilter(this.props.routesFilters, this.props.selectedRouteId));  
        this.map.setFilter('pins', this.generatePinsFilter(selectedPinCategories, routes, selectedRouteId))
      }
    }

    if(this.props.routesFilters !== prevProps.routesFilters) {
      this.map.setFilter('routes', this.generateRoutesFilter(this.props.routesFilters, this.props.selectedRouteId));  
      this.map.setFilter('routes-labels', this.generateRoutesFilter(this.props.routesFilters, this.props.selectedRouteId));  
    }

    if(this.props.pinRouteAssociationMode !== prevProps.pinRouteAssociationMode) {
      const visibility = this.props.pinRouteAssociationMode ? 'none' : 'visible';
      this.map.setLayoutProperty('pins', 'visibility', visibility);
    }
  }

  loadRoutesOnMap = () => {
    const { routes, currentMapStyle, selectedRouteId, routesFilters } = this.props;

    if(!routes) return;

    const routesGeojson = {
      type: 'FeatureCollection',
      features: Object.values(routes).map(route => ({
        type: 'Feature',
        geometry: route.geometry,
        properties: {
          id: route.id,
          name: route.name,
          distance: route.distance,
          time: route.time
        }
      }))
    };

    this.map.addSource('routes', {
      type: 'geojson',
      data: routesGeojson
    });

    let lineWidth = 3;
    let lineOpacity = 1;
    if(selectedRouteId) {
      lineWidth = ['match', ['get', 'id'], selectedRouteId, 5, 3];
      lineOpacity = ['match', ['get', 'id'], selectedRouteId, 1, 0];
    }
    
    this.map.addLayer({
      'id': 'routes',
      'source': 'routes',
      'type': 'line',
      'layout': {
        'line-join': 'round',
        'line-cap': 'round'
      },
      'paint': {
        'line-color': currentMapStyle === 'satellite' ? '#CCFF00' : '#1D2B4C',
        'line-width': lineWidth,
        'line-opacity': lineOpacity
      },
      'filter': this.generateRoutesFilter(routesFilters, selectedRouteId)
    });

    this.map.addLayer({
      'id': 'routes-labels',
      'source': 'routes',
      'type': 'symbol',
      'layout': {
          'symbol-placement': 'line-center',
          'text-field': ['get', 'name'],
          'text-size': 13,
          'text-anchor': 'bottom',
          'text-max-angle': 360,
          'text-rotation-alignment': 'viewport'
      },
      'paint': {
        'text-color': currentMapStyle === 'satellite' ? 'white' : '#018100'
      },
      'filter': this.generateRoutesFilter(routesFilters, selectedRouteId)
    });

    this.map.on('mouseenter', 'routes', () => {
      if(!this.props.newPinToolIsSelected) {
        this.map.getCanvas().style.cursor = 'pointer';
      }
    });
    this.map.on('mouseleave', 'routes', () => {
      if(!this.props.newPinToolIsSelected) {
        this.map.getCanvas().style.cursor = '';
      }
    });
    this.map.on('click', 'routes', e => {
      const routeId = e.features[0].properties.id;
      if(this.props.selectedRouteId !== routeId) {
        this.props.selectRoute(routeId);
      } else {
        this.props.selectRoute(null);
      }
    });
    this.setState({ routesLoadedOnMap: true }, () => {
      if(this.state.pinsLoadedOnMap) this.props.onFinishMapLoading();
    });
  }

  loadPinsOnMap = () => {
    const { pins, routes, currentMapStyle, selectedPinCategories, selectedRouteId, pinRouteAssociationMode } = this.props;

    if(!pins) return;

    const pinsGeojson = {
      type: 'FeatureCollection',
      features: Object.values(pins).map(pin => ({
        type: 'Feature',
        geometry: pin.geometry,
        properties: {
          id: pin.id,
          category: pin.category,
          name: pin.name
        }
      }))
    };

    this.map.addSource('pins', {
      type: 'geojson',
      data: pinsGeojson
    });

    Promise.all(
      categoryIcons.map(category => new Promise((resolve, reject) => {
        this.map.loadImage(category.icon, (error, image) => {
          if (error) throw error;
          this.map.addImage(category.name, image);
          resolve();
        })
      }))
    )
      .then(() => {
        this.map.addLayer({
          'id': 'pins',
          'source': 'pins',
          'type': 'symbol',
          'layout': {
            'visibility': pinRouteAssociationMode ? 'none' : 'visible',
            'icon-image': ['get', 'category'],
            'icon-size': .65,
            'icon-anchor': 'bottom',
            // 'icon-allow-overlap': true,
            'text-field': ['get', 'name'],
            'text-size': 12,
            'text-anchor': 'top-left',
            'text-offset': [1.5, -2.5],
            // 'text-allow-overlap': true
          },
          'paint': {
            'text-color': currentMapStyle === 'satellite' ? 'white' : '#018100'
          },
          'filter': this.generatePinsFilter(selectedPinCategories, routes, selectedRouteId)
        });

        this.map.on('mouseenter', 'pins', () => {
          if(!this.props.newPinToolIsSelected) {
            this.map.getCanvas().style.cursor = 'pointer';
          }
        });
        this.map.on('mouseleave', 'pins', () => {
          if(!this.props.newPinToolIsSelected) {
            this.map.getCanvas().style.cursor = '';
          }
        });
        this.map.on('click', 'pins', e => {
          const pinId = e.features[0].properties.id;
          if(this.props.selectedPinId !== pinId) {
            this.props.selectPin(pinId);
          } else {
            this.props.selectPin(null);
          }
        });
        this.setState({ pinsLoadedOnMap: true }, () => {
          if(this.state.routesLoadedOnMap) this.props.onFinishMapLoading();
        });
      });
  }

  generateRoutesFilter = (routesFilters, selectedRouteId) => {
    const filter = [
      'all',
      ['>=', ['get', 'distance'], routesFilters.distance.min],
      ['<=', ['get', 'distance'], routesFilters.distance.max],
      ['>=', ['get', 'time'], routesFilters.time.min * 60],
      ['<=', ['get', 'time'], routesFilters.time.max * 60],
    ];

    if (selectedRouteId) {
      filter.push(['==', ['get', 'id'], selectedRouteId]);
    }
    
    return filter;
  }

  generatePinsFilter = (selectedPinCategories, routes, selectedRouteId) => {
    return (selectedRouteId)
      ? (routes[selectedRouteId].pins.length === 0)
        ? false
        : [
          'all',
          ['match', ['get', 'id'], routes[selectedRouteId].pins, true, false],
          ['match', ['get', 'category'], selectedPinCategories, true, false]
        ]
      : ['match', ['get', 'category'], selectedPinCategories, true, false];
  }

  updatePinsOnMap = () => {
    const { pins } = this.props;

    const pinsGeojson = {
      type: 'FeatureCollection',
      features: Object.values(pins).map(pin => ({
        type: 'Feature',
        geometry: pin.geometry,
        properties: {
          id: pin.id,
          category: pin.category,
          name: pin.name
        }
      }))
    };

    this.map.getSource('pins').setData(pinsGeojson);
  }

  setNewPin = e => {
    const coordinates = [ e.lngLat.lng, e.lngLat.lat ];
    this.newPinMarker
      .setLngLat(coordinates)
      .addTo(this.map);
    this.props.toggleNewPinTool();
    this.props.setModalPage(MODAL_PAGES.NEW_PIN, { coordinates });
  }

  shiftMapToCenterPin(pinCoords) {
    const pinPixelCoords = this.map.project(pinCoords);
    const centeredPixelCoords = [window.innerWidth / 2, 435];
    const pixelCoordsTranslate = [pinPixelCoords.x - centeredPixelCoords[0], pinPixelCoords.y - centeredPixelCoords[1]];
    const currentCenterPixelCoords = this.map.project(this.map.getCenter());
    const newCenterPixelCoords = [currentCenterPixelCoords.x + pixelCoordsTranslate[0], currentCenterPixelCoords.y + pixelCoordsTranslate[1]];
    this.map.easeTo({ center: this.map.unproject(newCenterPixelCoords), duration: 500 });    
  }

  render() {
    const mapContainerStyle = {
      position: 'absolute',
      top: 0,
      bottom: 0,
      width: '100%',
      font: 'inherit'
    };
    return (
      <div 
        ref={this.mapContainer}
        style={mapContainerStyle}
      >
        {this.props.children}
      </div>
    );
  }
}

export default Map;