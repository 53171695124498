export const SITE_URL = (process.env.NODE_ENV === 'production')
	? 'https://greenpin.uma.es'
	: 'http://localhost:3000';

export const API_URL = (process.env.NODE_ENV === 'production')
	? 'https://api.greenpin.uma.es'
	: 'http://localhost:1337';

export const MAPBOX_ACCESS_TOKEN = 'pk.eyJ1IjoiY2FydG9tZXRyaWNzIiwiYSI6ImNqOGJ2ZXIzazAxd3kyd3AyMDVrOGpzNWkifQ.KwvwFfoDOeLnjR1gEHO8tg';
export const STYLES = {
	outdoors: 'mapbox://styles/mapbox/outdoors-v11',
	satellite: 'mapbox://styles/mapbox/satellite-v9',
	streets: 'mapbox://styles/mapbox/streets-v11'
};
export const INITIAL_STYLE = 'outdoors';
export const INITIAL_MAP = {
	style: 'mapbox://styles/mapbox/outdoors-v11',
	center: [-4.383, 36.816],
	zoom: 12,
	attributionControl: false
};

export const ROUTES = {
	ME: '/users/me',
	REGISTER: '/auth/local/register',
	LOGIN: '/auth/local',
	FORGOT: '/auth/forgot-password',
	RESET: '/auth/reset-password',
	USERS: '/users',
	PINS: '/pins',
	ROUTES: '/routes',
	SAVE_ROUTE: '/users/save-route'
};

export const AUTH_ERRORS = {
	EMAIL_TAKEN: 'Auth.form.error.email.taken',
	USERNAME_TAKEN: 'Auth.form.error.username.taken',
	EMAIL_PASSWORD_INVALID: 'Auth.form.error.invalid',
	ACCOUNT_BLOCKED: 'Auth.form.error.blocked',
	EMAIL_NOT_EXIST: 'Auth.form.error.user.not-exist'
}

export const NOTIFICATION_DURATION = 4000; // ms

export const MODAL_PAGES = {
	NEW_PIN: 'newPin',
	EDIT_PIN: 'editPin',
	PINS_MENU: 'pinsMenu',
	ROUTES_MENU: 'routesMenu',
	USER_PANEL: 'userPanel',
	RULES: 'rules'
};

export const CATEGORIES = [
	'recreational',
	'birdwatching',
	'establishment',
	'geology',
	'heritage',
	'spring',
	'lookout'
];

export const RANKS_REQUIRED_PINS = [ 5, 10 ];

export const MAX_PIN_IMAGE_SIZE = 10000000;  // 10MB

export const ROUTE_FILTER_MAX_DISTANCE = 25;
export const ROUTE_FILTER_MAX_TIME = 5;

export const INTRO_STEPS = [
	{
		element: '.intro-steps-zero',
		intro: 'introSteps.zero'
	},
	{
		element: '.intro-steps-one',
		intro: 'introSteps.one'
	},
	{
		element: '.intro-steps-two',
		intro: 'introSteps.two',
	},
	{
		element: '.intro-steps-three',
		intro: 'introSteps.three',
	},
	{
		element: '.intro-steps-four',
		intro: 'introSteps.four',
	},
	{
		element: '.intro-steps-five',
		intro: 'introSteps.five',
	},
	{
		element: '.intro-steps-six',
		intro: 'introSteps.six',
	}
];