import React from 'react';
import { withTranslation } from 'react-i18next';
import classes from './Legal.module.scss';
import logo from '../../assets/logos/greenpin.svg';

const PrivacyPolicy = ({ t }) => (
	<div className={classes.pageContainer}>
		<div className={classes.logoContainer}>
    	<span>Greenpin</span>
      <img src={logo} alt='logo' />
    </div>
    <h1 className={classes.header}>
    	{t('privacyPolicy.header')}
    </h1>
    <div className={classes.block}>
	    <h2 className={classes.subheader}>
	    	{t('privacyPolicy.one.subheader')}
	    </h2>
	    <p>{t('privacyPolicy.one.par1')}</p>
	    <p>{t('privacyPolicy.one.par2')}</p>
	    <p>{t('privacyPolicy.one.par3')}</p>
    </div>
    <div className={classes.block}>
	    <h2 className={classes.subheader}>
	    	{t('privacyPolicy.two.subheader')}
	    </h2>
	    <p>{t('privacyPolicy.two.par1')}</p>
	    <p>{t('privacyPolicy.two.par2')}</p>
	    <p>{t('privacyPolicy.two.par3')}</p>
	    <p>{t('privacyPolicy.two.par4')}</p>
    </div>
    <div className={classes.block}>
	    <h2 className={classes.subheader}>
	    	{t('privacyPolicy.three.subheader')}
	    </h2>
	    <p>{t('privacyPolicy.three.par1')}</p>
    </div>
    <div className={classes.block}>
	    <h2 className={classes.subheader}>
	    	{t('privacyPolicy.four.subheader')}
	    </h2>
	    <p>{t('privacyPolicy.four.par1')}</p>
	    <p>{t('privacyPolicy.four.par2')}</p>
	    <p>{t('privacyPolicy.four.par3')}</p>
	    <p>{t('privacyPolicy.four.par4')}</p>
    </div>
	</div>
);

export default withTranslation()(PrivacyPolicy);