import React from 'react';
import { withTranslation } from 'react-i18next';
import InputRange from 'react-input-range';
import classes from './RoutesFilters.module.scss';
import { ROUTE_FILTER_MAX_DISTANCE, ROUTE_FILTER_MAX_TIME } from '../../config';
import { ReactComponent as FiltersIcon } from '../../assets/icons/filters.svg';
import { ReactComponent as MinimizeIcon } from '../../assets/icons/minimize.svg';
import { ReactComponent as DistanceIcon } from '../../assets/icons/distance.svg';
import { ReactComponent as TimeIcon } from '../../assets/icons/time.svg';

const RoutesFilters = (props) => {

	const {
		pinRouteAssociationMode,
		routesFiltersOpen,
		routesFilters,
		toggleRoutesFilters,
		setRoutesFilters,
		resetRoutesFilters,
		t
	} = props;

	return pinRouteAssociationMode ? null : (
		<React.Fragment>
			<button
				className={`button intro-steps-six ${routesFiltersOpen ? classes.filtersButton : classes.filtersButtonShow}`}
				onClick={toggleRoutesFilters}
			>
				<FiltersIcon className={classes.filtersButtonIcon} />
			</button>
			<span className={classes.tooltip}>{t('tooltips.filters')}</span>
			<div className={routesFiltersOpen ? classes.filtersContainerOpen : classes.filtersContainer}>
				<button 
					className={`button ${classes.minimizeButton}`}
					onClick={toggleRoutesFilters}
				>
					<MinimizeIcon className={classes.minimizeIcon} />
				</button>
				<div className={classes.inputRangeContainer}>
					<DistanceIcon className={classes.filterIcon}/>
					<InputRange
		        maxValue={ROUTE_FILTER_MAX_DISTANCE}
		        minValue={0}
		        formatLabel={value => `${value} km`}
		        value={routesFilters.distance}
		        onChange={distance => setRoutesFilters('distance', distance)}
		      />
				</div>
				<div className={classes.inputRangeContainer}>
					<TimeIcon className={classes.filterIcon} style={{ top: '-10px' }} />
		      <InputRange
		        maxValue={ROUTE_FILTER_MAX_TIME}
		        minValue={0}
		        formatLabel={value => `${value} h`}
		        value={routesFilters.time}
		        onChange={time => setRoutesFilters('time', time)}
		      />
		    </div>
		    <button
		    	className={`button ${classes.resetButton}`}
		    	onClick={resetRoutesFilters}
		    >
		    	{t('routesFilters.reset')}
		    </button>
			</div>
		</React.Fragment>
	);
};

export default withTranslation()(RoutesFilters);