import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import classes from './MapStyleButton.module.scss';
import { STYLES } from '../../config';
import { ReactComponent as LayersIcon } from '../../assets/icons/layers.svg';
import { ReactComponent as TriangleIcon } from '../../assets/icons/triangle.svg';

const MapStyleButton = (props) => {

	const [ menuOpen, setMenuOpen ] = useState(false);

	const toggleMenu = () => {
		setMenuOpen(prevMenuOpen => !prevMenuOpen);
	}

	const selectMapStyle = (newMapstyle) => {
		setMenuOpen(false);
		props.changeMapStyle(newMapstyle);
	}

	const { currentMapStyle, t } = props;

	return(
		<div className={classes.container}>
			<button
				className={`button intro-steps-two ${classes.mapStyleButton}`}
				onClick={toggleMenu}>
				<LayersIcon className={classes.layersIcon} />
			</button>
			<span className={classes.tooltip}>{t('tooltips.mapStyle')}</span>
			<aside className={`menu ${menuOpen ? classes.menuOpen : classes.menu}`}>
				<TriangleIcon className={classes.triangleIcon} />
				<ul className="menu-list">
					{Object.keys(STYLES).map(mapStyle => (
						<li key={mapStyle}>
							<button 
								className={`button ${(mapStyle === currentMapStyle) ? classes.menuButtonSelected : classes.menuButton}`}
								onClick={selectMapStyle.bind(this, mapStyle)}
							>
								{t(`styles.${mapStyle}`)}
							</button>
						</li>
					))}
				</ul>
			</aside>
		</div>
	);
};

export default withTranslation()(MapStyleButton);