import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import axios from 'axios';
import classes from './UserPanel.module.scss';
import ConfirmationMessage from '../ConfirmationMessage/ConfirmationMessage';
import { formatTime } from '../../utils';
import { ROUTES } from '../../config';
import { ReactComponent as StarIcon } from '../../assets/icons/star.svg';
import { ReactComponent as PinIcon } from '../../assets/icons/pin.svg';
import bronzeIcon from '../../assets/icons/bronze.svg';
import silverIcon from '../../assets/icons/silver.svg';
import goldIcon from '../../assets/icons/gold.svg';

const ranksIcons = { bronze: bronzeIcon, silver: silverIcon, gold: goldIcon };
const reqCategoryIcons = require.context('../../assets/icons/categories', false, /\.png$/);

const UserPanel = (props) => {

	const {
		currentUser,
		routes,
		pins,
		selectRoute,
		selectPin,
		logout,
		setNotification,
		closeModal,
		t
	} = props;

	const [ status, setStatus ] = useState(null);
	const [ deleteConfirmationShown, setDeleteConfirmationShown ] = useState(false);

	const deleteUser = () => {
		if(status === 'sending') return;
		setStatus('sending');

		axios
		  .delete(ROUTES.USERS + '/' + currentUser.id)
		  .then(res => {
		  	setStatus('success');
		  	closeModal();
		  	logout();
		  	setNotification({
		  		type: 'success',
		  		message: t('userPanel.notifications.delete')
		  	});
		  })
		  .catch(error => {
		  	setStatus('error');
		  	setDeleteConfirmationShown(false);
		  	setNotification({
		  		type: 'error',
		  		message: t('userPanel.errors.internalDelete')
		  	});
		  });
	}

	let routesList = null;
	let pinsList = null;
	if(currentUser && routes) {
		routesList = currentUser.routes.map(routeId => (
			<li
				key={routeId}
				className={classes.route}
				onClick={() => selectRoute(routeId)}
			>
				<span className={classes.routeName}>
					{routes[routeId].name}
				</span>
				<div className={classes.routeDetails}>
					<span>{`${routes[routeId].distance} ${t('common.km')}`}</span>
					<span>{`${formatTime(routes[routeId].time)} ${t('common.hours')}`}</span>
					<span>{`${routes[routeId].slope} ${t('common.slope')}`}</span>
				</div>
			</li>
		))
	}
	if(currentUser && pins) {
		pinsList = currentUser.pins.map(pinId => (
			<li
				key={pinId}
				className={classes.pin}
				onClick={() => selectPin(pinId)}
			>
		  	<img
		  		src={reqCategoryIcons(`./${pins[pinId].category}.png`)}
		  		className={classes.pinCategoryIcon}
		  		alt={pins[pinId].category}
		  	/>
				<span className={classes.pinName}>
					{pins[pinId].name}
				</span>
			</li>
		))
	}

	const confirmationMessage = (
		<ConfirmationMessage
			show={deleteConfirmationShown}
			header={t('userPanel.deleteConfirmation.header')}
			text={t('userPanel.deleteConfirmation.text')}
			cancel={t('userPanel.deleteConfirmation.cancel')}
			confirm={t('userPanel.deleteConfirmation.confirm')}
			sending={status === 'sending'}
			handleCancel={() => setDeleteConfirmationShown(false)}
			handleConfirm={deleteUser}
		/>
	);

	return (
		<React.Fragment>
			<div className={classes.headerContainer}>
				<h1 className={classes.header}>
					{t('userPanel.header')}
				</h1>
			</div>
			<div className={classes.userContainer}>
				<div className={classes.userName}>
					{currentUser.username}
				</div>
				<div className={classes.userRank}>
					<span className={classes.label}>
						{t('userPanel.rank')}
					</span>
					<span className={classes.rank}>
						{t(`ranks.${currentUser.rank}`)}
					</span>
					<img className={classes.rankIcon} src={ranksIcons[currentUser.rank]} alt='rank' />
				</div>
				<div className={classes.userPins}>
					{`(${currentUser.pins.length} ${t('common.pins')})`}
				</div>
				<div className={classes.ranksMessage}>
					<p>{t('userPanel.ranksMessagePar1')}</p>
					<p>{t('userPanel.ranksMessagePar2')}</p>
				</div>
			</div>
			<div className={classes.subheaderContainer}>
				<div className={classes.subheaderIconContainer}>
					<StarIcon className={classes.starIcon} />
				</div>
				<h1 className={classes.subheader}>
					{t('userPanel.routes')}
				</h1>
			</div>
			<div className={classes.routesPinsContainer}>
				<ul>
					{routesList}
				</ul>
			</div>
			<div className={classes.subheaderContainer}>
				<div className={classes.subheaderIconContainer}>
					<PinIcon className={classes.pinIcon} />
				</div>
				<h1 className={classes.subheader}>
					{t('userPanel.pins')}
				</h1>
			</div>
			<div className={classes.routesPinsContainer}>
				<ul>
					{pinsList}
				</ul>
			</div>
			<button
				className={`button ${classes.deleteButton} ${(status === 'sending') ? 'is-loading' : ''}`}
				onClick={() => setDeleteConfirmationShown(true)}
			>
				{t('userPanel.deleteAccount')}
			</button>
			{confirmationMessage}
		</React.Fragment>
	);
};

export default withTranslation()(UserPanel);