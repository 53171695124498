import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import classes from './Intro.module.scss';
import logo from '../../assets/logos/greenpin.svg';
import { ReactComponent as PinIcon } from '../../assets/icons/pin.svg';
import { ReactComponent as RouteIcon } from '../../assets/icons/route.svg';
import { ReactComponent as StarIcon } from '../../assets/icons/star.svg';
import logoAyuntamiento from '../../assets/logos/ayuntamiento.png';
import logoRedCatedras from '../../assets/logos/red-catedras.png';
import logoGeotecnologia from '../../assets/logos/geotecnologia.png';
import logoVicerrectorado from '../../assets/logos/vicerrectorado.png';
import logoPolo from '../../assets/logos/polo.png';

const sponsorContainerClasses = ['unseenLeft', 'seenLeft', 'seenMedium', 'seenRight', 'unseenRight'];
const initialSponsors = [
	{key: 'polo', logo: logoPolo},
	{key: 'ayuntamiento', logo: logoAyuntamiento},
	{key: 'cátedras', logo: logoRedCatedras},
	{key: 'geotecnología', logo: logoGeotecnologia},
	{key: 'vicerrectorado', logo: logoVicerrectorado}
];

const Intro = ({ show, showIntroSteps, t }) => {

	const [ carousel, setCarousel ] = useState(initialSponsors); 

	useEffect(() => {
		setInterval(rotateCarousel, 2000);
	}, []);

	const rotateCarousel = () => {
		setCarousel(prevCarousel => {
			const rotatedCarousel = [ ...prevCarousel ];
			rotatedCarousel.push(rotatedCarousel.shift());
			return rotatedCarousel;
		});
	}

	return (
		<div className={show ? classes.modalShown : classes.modal}>
			<div className={classes.modalBackground} onClick={showIntroSteps}></div>
			<div className={classes.modalDialog}>
				<div className={classes.introContent}>
					<div className={classes.textSection}>
						<div className={classes.logoContainer}>
				    	<span>Greenpin</span>
				      <img src={logo} alt='logo' />
				    </div>
						<ul className={classes.textList}>
							<li>{t('introPage.line1')}</li>
							<li>{t('introPage.line2')}</li>
							<li>{t('introPage.line3')}</li>
							<li>{t('introPage.line4')}</li>
						</ul>
					</div>
					<div className={classes.iconsSection}>
						<div className={classes.pinIconContainer}>
							<PinIcon className={classes.pinIcon} />
						</div>
						<div className={classes.routeIconContainer}>
							<RouteIcon className={classes.routeIcon} />
						</div>
						<div className={classes.starIconContainer}>
							<StarIcon className={classes.starIcon} />
						</div>
					</div>
					<div className={classes.sponsorsSection}>
						<h2 className={classes.sponsorsHeader}>
							{t('introPage.sponsors')}
						</h2>
						<div className={classes.sponsorsContainer}>
							{carousel.map((sponsor, idx) => (
								<div className={classes[sponsorContainerClasses[idx]]} key={sponsor.key}>
									<img src={sponsor.logo} alt={sponsor.key} />
								</div>
							))}
						</div>
						<button
							className={`button ${classes.secondaryButton}`}
							onClick={showIntroSteps}
						>
							{t('introPage.next')}
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default withTranslation()(Intro);